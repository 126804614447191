"use client";

import { useCallback } from "react";
import ServerError from '@/modules/home/pages/ServerError';

const KEY_RELOAD_ATTEMPTS = "reload-attempts";
const VALUE_RELOAD_ATTEMTS = "1";

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  const attempts = localStorage.getItem(KEY_RELOAD_ATTEMPTS);

  if (process.env.NODE_ENV === "production" && attempts !== VALUE_RELOAD_ATTEMTS) {
    global.location.href = global.location.href;
    localStorage.setItem(KEY_RELOAD_ATTEMPTS, VALUE_RELOAD_ATTEMTS);
  }

  const onReset = useCallback(() => {
    localStorage.setItem(KEY_RELOAD_ATTEMPTS, "");
    reset();
  }, [reset]);

  console.error("Crashes page error:", error);

  return (
    <ServerError meta={error} />
    // <div>
    //   <h2>Something went wrong!</h2>
    //   <button onClick={onReset}>Try again</button>
    // </div>
  );
}
